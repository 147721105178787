import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { PRODUCTION } from '../enums/appEnums';
import npm_package_version from '../meta.json';

export default async function initializeLogger() {
    if (process.env.REACT_APP_ENVIRONMENT === PRODUCTION) {
        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_URL,
            integrations: [new Integrations.BrowserTracing()],
            beforeSend(event, hint) {
                const isNonErrorException =
                    event &&
                    event.message &&
                    event.message.match(/SentryError: HTTP Error \(429\)/) &&
                    (event.exception.values[0].value.startsWith('Non-Error exception captured') ||
                        hint.originalException['message'].startsWith(
                            'Non-Error exception captured',
                        ));
                if (isNonErrorException) {
                    return null;
                }
                return event;
            },
            environment: process.env.REACT_APP_ENVIRONMENT,

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,
            release: npm_package_version['version'],
        });
    }
}

export async function setUserInErrorLogger(email) {
    if (process.env.REACT_APP_ENVIRONMENT === PRODUCTION) {
        Sentry.setUser({ email });
    }
}

export async function unsetUserInErrorLogger() {
    if (process.env.REACT_APP_ENVIRONMENT === PRODUCTION) {
        Sentry.configureScope((scope) => scope.setUser(null));
    }
}

export async function captureMessagesViaErrorLogger(message) {
    if (process.env.REACT_APP_ENVIRONMENT === PRODUCTION) {
        Sentry.captureMessage(message);
    } else console.error(message);
}

export async function captureExceptionViaErrorLogger(func) {
    if (process.env.REACT_APP_ENVIRONMENT === PRODUCTION) {
        try {
            func();
        } catch (err) {
            Sentry.captureException(err);
        }
    }
}
